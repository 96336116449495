const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  _clinicAlias: (clinicAlias: string | number) => ({
    "patients": {
      _patientId: (patientId: string | number) => ({
        "health_check": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients/[patientId]/health-check' as const, query: { clinicAlias, patientId }, hash: url?.hash, path: `/${clinicAlias}/patients/${patientId}/health-check${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients/[patientId]' as const, query: { clinicAlias, patientId }, hash: url?.hash, path: `/${clinicAlias}/patients/${patientId}${buildSuffix(url)}` })
      }),
      "reservations": {
        _reservationId: (reservationId: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients/reservations/[reservationId]' as const, query: { clinicAlias, reservationId }, hash: url?.hash, path: `/${clinicAlias}/patients/reservations/${reservationId}${buildSuffix(url)}` })
        })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients' as const, query: { clinicAlias }, hash: url?.hash, path: `/${clinicAlias}/patients${buildSuffix(url)}` })
    },
    "reservations": {
      _date: (date: string | number) => ({
        "chart": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/chart' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/chart${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}${buildSuffix(url)}` })
      }),
      "calendar": {
        _date: (date: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/calendar/[date]' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/calendar/${date}${buildSuffix(url)}` })
        })
      },
      "slots": {
        _ccid: (ccid: string | number) => ({
          _date: (date: string | number) => ({
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/slots/[ccid]/[date]' as const, query: { clinicAlias, ccid, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/slots/${ccid}/${date}${buildSuffix(url)}` })
          })
        })
      }
    },
    "upload_healthchecks": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/upload-healthchecks' as const, query: { clinicAlias }, hash: url?.hash, path: `/${clinicAlias}/upload-healthchecks${buildSuffix(url)}` })
    }
  }),
  "login": {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash, path: `/login${buildSuffix(url)}` })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash, path: `/${buildSuffix(url)}` })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  favicon_ico: '/favicon.ico',
  image: {
    building_svg: '/image/building.svg',
    invitation_svg: '/image/invitation.svg',
    logo_tate_png: '/image/logo_tate.png',
    logo_yoko_png: '/image/logo_yoko.png',
    react_jpg: '/image/react.jpg'
  },
  index_html: '/index.html',
  page_not_found_svg: '/page_not_found.svg'
} as const;

export type StaticPath = typeof staticPath;
